<template>
	<div class="home">
		<c-hero @showModal="onToggleModal"/>

		<section class="section">
			<div class="container-xl">
				<div class="features">
					<div class="features__inner">
						<div class="row">
							<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
								class="col-12 col-md-6 col-lg-4"
							>
								<div class="features__item">
									<div class="row" :class="{'flex-row-reverse': index % 2 !== 0}">
										<div class="col-6 col-md-12">
											<span class="features__num">{{ index + 1 }}</span>
											<figure class="features__img">
												<img :src="oFeature.img" alt="">
											</figure>
										</div>

										<div class="col-6 col-md-12">
											<div class="features__title">{{ oFeature.title }}</div>
											<p>{{ oFeature.content }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section section--bg">
			<div class="container-xl">
				<div class="section-heading">
					<h2 class="section-heading__title">Newly registered girls</h2>
				</div>

				<div class="profile">
					<div class="profile__inner">
						<div class="row">
							<div v-for="oProfile in aProfiles" :key="oProfile.id"
								class="col-6 col-lg-4 col-xl-3"
							>
								<div class="profile__item"
									@click="onToggleModal"
								>
									<i class="profile__status">new</i>
									<figure class="profile__img">
										<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
									</figure>
									<div class="profile__info">
										<div class="profile__name">{{ oProfile.name }}, <span class="d-block d-sm-inline">{{ oProfile.age }} years</span></div>
										<div class="profile__pref">
											<span class="d-none d-lg-inline">I need:</span>
											<i v-for="i in 3" :key="'i' + i" :class="'i-' + i"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section">
			<div class="container-xl">
				<div class="section-heading">
					<h2 class="section-heading__title">Latest stories</h2>
					<i class="section-heading__icon"></i>
				</div>

				<div class="post">
					<div class="post__inner">
						<div class="post__heading"><span>Latest from the blog</span></div>

						<div v-for="(oPost, index) in aPosts" :key="'p' + index"
							@click="onToggleModal"
							class="post__item" :class="{'post__item--rev': index % 2 === 0}"
						>
							<div class="row no-gutters">
								<div class="col-5 col-sm-6">
									<figure class="post__img">
										<img v-if="oPost.img" :src="oPost.img" alt="demo" />
									</figure>
								</div>

								<div class="col-7 col-sm-6">
									<div class="post__content"> 
										<div class="post__title">{{ oPost.title }}</div>
										<p>{{ oPost.content }}</p>
										<div class="post__footer">
											<span class="post__more">Read more</span>
											<span class="post__date">{{ oPost.date }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- <div class="row no-gutters">
							<div class="col-12 col-md">
								<div v-for="(oPost, index) in aPosts" :key="'p' + index" 
									class="post__item" :class="{'post__item--rev': index % 2 === 0}">
									<div class="row no-gutters">
										<div class="col-6">
											<figure class="post__img">
												<img v-if="oPost.img" :src="oPost.img" alt="demo" />
											</figure>
										</div>

										<div class="col-6">
											<div class="post__content"> 
												<div class="post__title">{{ oPost.title }}</div>
												<p>{{ oPost.content }}</p>
												<div class="post__footer">
													<span class="post__more">Read more</span>
													<span class="post__date">{{ oPost.date }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-md-auto">
								<div class="post__heading"><span>Latest from the blog</span></div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</section>

		<section class="section section--bg">
			<div class="container-xl">
				<div class="section-heading">
					<h2 class="section-heading__title">Profiles</h2>
				</div>

				<div class="profile">
					<nav class="profile__filter">
						<span @click="onToggleModal" class="active">All</span>
						<span @click="onToggleModal">Female</span>
						<span @click="onToggleModal">Male</span>
					</nav>

					<div class="profile__slider" ref="glide">
						<div data-glide-el="track" class="glide__track">
							<div class="glide__slides">
								<div
									v-for="(oProfile, index) in aPeoples"
									:key="'glide__slide' + index"
									class="glide__slide"
								>
									<div class="profile__item"
										@click="onToggleModal"
									>
										<figure class="profile__img">
											<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
										</figure>
										<div class="profile__info">
											<div class="profile__name">{{ oProfile.name }}, <span class="d-block d-sm-inline">{{ oProfile.age }} years</span></div>
											<div class="profile__pref">
												<i v-for="i in 3" :key="'i' + i" :class="'i-' + i"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="glide__bullets" data-glide-el="controls[nav]">
							<button type="button" class="glide__bullet"
								v-for="index in aPeoples.length"
								:key="'glide__bullet' + index"
								:data-glide-dir="'=' + (index - 1)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer class="footer">
			<div class="container-xl">
				<img class="footer__logos img-fluid" src="@/assets/footer-logos.png" width="330" height="35" alt="logos" />
				<p>Onlinedating.com {{ (new Date()).getFullYear() }} &copy; All rights reserved</p>
			</div>
		</footer>

		<transition name="fade">
			<div v-if="!bHideModal" class="modal">
				<div class="modal__inner">
					<div class="container">
						<form-signup />
					</div>
				</div>

				<button class="modal__close" @click="onToggleModal" type="button"></button>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import Glide     from '@glidejs/glide';
import scrolling from '@/utils/scrolling';

import Hero       from '@/components/Hero.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'home',

	components: {
		'c-hero': Hero,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,

			aProfiles: [],
			aPeoples: [],
			aFeatures: Object.freeze([
				{
					img: require('@/assets/features/1.jpg'),
					title: 'Account',
					content: 'Provide basic personal information such as name and email.'
				},
				{
					img: require('@/assets/features/2.jpg'),
					title: 'Profiles',
					content: 'Tell more about your personality and life to attract the right girls.'
				},
				{
					img: require('@/assets/features/3.jpg'),
					title: 'Love stories',
					content: 'Set search filters, choose the best girl, and start communication.'
				}
			]),
			aPosts: Object.freeze([
				{
					img: require('@/assets/post/1.jpg'),
					date: 'August 4, 2021',
					title: 'My perfect date happened....',
					content: 'My perfect date happened, like all beautiful things, probably, when I least expected it. After a six-month break in my relationship, during which even the very thought of meeting someone new caused me mental discomfort, I decided it was time to finally get out of my shell and start dating, because if you didn\'t take the first step ...'
				},
				{
					img: require('@/assets/post/2.jpg'),
					date: 'August 6, 2021',
					title: 'This was my first date in years...',
					content: 'This was my first date in years. Then I was already in my 5th year at the University of Architecture, preparing for a diploma, a trip to Rome, and before that I watched my family collapse for a long time. My parents\' divorce greatly affected my mental health. The diploma has added its own...'
				}
			])
		};
	},

	created: async function ()
	{
		var aUsers = await this.fetchUsers();

		this.aProfiles = Object.freeze(aUsers.slice(0, 12));
		this.aPeoples = Object.freeze(aUsers.slice(12, 20));

		aUsers = null;

		this.$nextTick(this.init);

		await this.sendPidAndSid();
	},

	mounted: function ()
	{
		
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			var options = {
					method: 'post',
					url: '/api/?action=get_profiles'
				};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;


			var oSearchParams = new URL(document.location).searchParams,
				sData = 'pid=' + oSearchParams.get('pid') + '&sid=' + oSearchParams.get('sid') + '&referer=' + document.referrer,
				options = {
					method: 'post',
					url: '/api/?action=send_partner_info',
					data: sData
				};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		},

		init: function ()
		{
			var option = {
					type: 'carousel',
					perView: 4,
					gap: 30,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 500,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)',
					breakpoints: {
						991: {
							perView: 3
						},
						575: {
							perView: 2,
							gap: 10
						}
					}
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}
</script>

<style lang="scss">
@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import '../styles/style';

.features
{
	$offset-Y: 50px;

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;

		.row
		{
			margin-left: -5px;
			margin-right: -5px;
		}

		.col,
		[class*="col-"]
		{
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	&__num
	{
		position: absolute;
		top: 0;
		left: 5px;
		width: 60px;
		height: 60px;
		line-height: 60px;
		font-size: 3rem;
		text-align: center;
		color: #FFF;
		background-color: #804453;
		border-radius: 10px 0 10px 0;
		z-index: 1;
	}

	&__img
	{
		position: relative;
		overflow: hidden;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(320/290);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title
	{
		margin-bottom: 5px;
		line-height: 1;
		font-size: 2rem;
		font-weight: 700;
		color: #804453;
	}

	@media (min-width: 576px)
	{
		&__item
		{
			padding-top: 20px;
			padding-left: 30px;
		}

		&__num
		{
			width: 100px;
			height: 100px;
			line-height: 100px;
			font-size: 5rem;
			border-radius: 10px;
		}

		&__title
		{
			margin-bottom: 15px;
			font-size: 3.5rem;
		}
	}

	@media (min-width: 768px)
	{
		text-align: center;

		.col,
		[class*="col-"]
		{
			position: static;
		}

		&__title
		{
			margin-top: 20px;
		}
	}

	@media (min-width: 1200px)
	{
		&__item
		{
			padding-top: 45px;
			padding-left: 70px;
		}

		&__num
		{
			width: 130px;
			height: 130px;
			line-height: 130px;
		}
	}
}

.profile
{
	$offset-Y: 30px;

	&__filter
	{
		display: flex;
		justify-content: space-between;
		width: 80%;
		max-width: 400px;
		margin: 0 auto 30px;
		line-height: 1.2;
		font-size: 2rem;
		text-align: center;
		color: #828282;

		span
		{
			padding-left: 35px;
			cursor: pointer;
			transition: color 300ms ease-in-out;

			&:hover
			{
				color: #BC3232;
			}
		}

		.active
		{
			color: #BC3232;
			background: url('~@/assets/icon-heart.svg') no-repeat left center;
			text-shadow: 1px 0px 0px currentColor;
		}
	}

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		cursor: pointer;
		background-color: #FFF;
		border-radius: 10px;
		box-shadow: 0px 4px 10px rgba(188, 50, 50, 0.1);

		&:hover
		{
			img
			{
				transform: scale(1.2) translateZ(0);
			}
		}
	}

	&__status
	{
		position: absolute;
		top: 5px;
		left: 5px;
		width: 45px;
		height: 45px;
		line-height: 40px;
		font-size: 1.2rem;
		font-style: normal;
		text-align: center;
		text-transform: uppercase;
		color: #FFF;
		background: url('~@/assets/icon-heart.svg') no-repeat center;
		background-size: 45px;
		z-index: 1;
	}

	&__img
	{
		position: relative;
		overflow: hidden;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(330/270);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
			);
		}
	}

	&__info
	{
		padding: 20px 15px 15px;
		font-family: 'Source Serif Pro', serif;
		text-align: center;
		color: #804453;
	}

	&__name
	{
		margin-bottom: 15px;
		line-height: 1.2;
		font-size: 1.8rem;
	}

	&__pref
	{
		$size: 30px;

		display: flex;
		align-items: center;
		justify-content: space-evenly;
		line-height: 30px;
		font-size: 2rem;

		i
		{
			display: inline-block;
			vertical-align: top;
			width: $size;
			height: $size;
			margin-left: 5px;
			background-position: center;
			background-repeat: no-repeat;
		}

		.i-1
		{
			background-image: url('~@/assets/preferences/1.svg');
		}
		.i-2
		{
			background-image: url('~@/assets/preferences/2.svg');
		}
		.i-3
		{
			background-image: url('~@/assets/preferences/3.svg');
		}
	}

	.glide__bullets
	{
		margin-top: 20px;
	}

	@media (min-width: 576px)
	{
		&__filter
		{
			font-size: 2.5rem;
		}

		&__status
		{
			top: 15px;
			left: 15px;
			width: 65px;
			height: 65px;
			line-height: 60px;
			font-size: inherit;
			background-size: 65px;
		}

		&__name
		{
			font-size: 2.5rem;
		}
	}

	@media (max-width: 575px)
	{
		.row
		{
			margin-left: -5px;
			margin-right: -5px;
		}

		.col,
		[class*=" col-"],
		[class^=col-]
		{
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

.post
{
	margin-left: -15px;
	margin-right: -15px;

	&__inner
	{
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	&__heading
	{
		position: relative;
		display: table;
		width: 100%;
		min-height: 350px;
		margin: -35px 0;
		padding: 30px 0;
		font-size: 5rem;
		font-weight: 700;
		font-family: 'Source Serif Pro', serif;
		text-align: center;
		color: #FFF;
		background-color: #BC3232;

		> span
		{
			display: table-cell;
			vertical-align: middle;
			padding: 0 5%;
		}
	}

	&__item
	{
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		cursor: pointer;

		&--rev
		{
			.row
			{
				flex-direction: row-reverse;
			}

			.post__content
			{
				&::before
				{
					left: 100%;
					right: auto;
					border-left-color: #FFF;
					border-right: none;
				}
			}
		}

		&:nth-child(2)
		{
			order: -1;

			.post__content
			{
				border-radius: 0px 10px 10px 0px;
			}
		}
		&:last-of-type
		{
			.post__content
			{
				border-radius: 10px 0px 0px 10px;
			}
		}

		&:hover
		{
			img
			{
				transform: scale(1.2) translateZ(0);
			}
		}
	}

	&__img
	{
		position: relative;
		height: 100%;
		overflow: hidden;
		border-top: 35px solid #0000;
		border-bottom: 35px solid #0000;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(340/435);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
			);
		}
	}

	&__content
	{
		position: relative;
		min-height: 100%;
		padding: 50px 15px 25px;
		background-color: #FFF;

		&::before
		{
			content: '';
			position: absolute;
			top: 50%;
			right: 100%;
			pointer-events: none;
			border-style: solid;
			border-color: transparent;
			border-width: 20px;
			height: 0;
			width: 0;
			border-right-color: #FFF;
			margin-top: -20px;
		}
	}

	&__title
	{
		margin-bottom: 5px;
		line-height: 1;
		font-size: 1.8rem;
		font-weight: 700;
		color: #804453;
	}

	&__footer
	{
		padding-top: 15px;
		line-height: 1.1;
	}

	&__date
	{
		position: absolute;
		top: 25px;
		left: 15px;
		font-size: 1.4rem;
		font-family: 'Source Serif Pro', serif;
		color: #804453;
	}

	&__more
	{
		line-height: 1;
		font-size: 1.8rem;
		color: #BC3232;
	}

	@media (min-width: 576px)
	{
		margin-left: 0px;
		margin-right: 0px;

		&__heading
		{
			margin-top: 0;
			margin-bottom: 0;
			font-size: 6.5rem;
		}

		&__item
		{
			&:nth-child(2)
			{
				.post__content
				{
					border-radius: 10px 0 0 0;
				}
			}
			&:last-of-type
			{
				.post__img
				{
					border-radius: 0 0 0 10px;
				}

				.post__content
				{
					border-radius: 0;
				}
			}
		}

		&__img
		{
			background-color: #3E3E3E;
			border: none;
		}

		&__content
		{
			padding: 25px 30px 50px;
		}

		&__title
		{
			font-size: 3rem;
		}

		&__footer
		{
			position: absolute;
			left: 30px;
			right: 30px;
			bottom: 25px;
		}

		&__date
		{
			position: static;
		}

		&__more
		{
			float: right;
		}
	}

	@media (min-width: 992px)
	{
		&__inner
		{
			padding-right: 300px;
		}

		&__heading
		{
			position: absolute;
			top: 0;
			right: 0;
			max-width: 300px;
			min-height: 100%;
			border-radius: 0px 10px 10px 0px;
		}
	}
}

.footer
{
	padding-top: 35px;
	padding-bottom: 20px;
	line-height: 1.2;
	font-size: 1.4rem;
	color: #804453;
	text-align: center;

	&__logos
	{
		margin-bottom: 25px;
	}

	@media (min-width: 576px)
	{
		padding-bottom: 50px;

		&__logos
		{
			margin-bottom: 40px;
		}
	}
}

.glide
{
	&__bullets
	{
		font-size: 0;
		text-align: center;
	}

	&__bullet
	{
		width: 16px;
		height: 16px;
		margin-left: 20px;
		cursor: pointer;
		background-color: transparent;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 0 3px #804453 inset;
		transition: box-shadow 300ms ease-in;

		&:first-child
		{
			margin-left: 0;
		}

		&--active,
		&:hover
		{
			box-shadow: 0 0 0 8px #804453 inset;
		}

		&--light
		{
			box-shadow: 0 0 0 3px #FFF inset;

			&:hover,
			&.glide__bullet--active
			{
				box-shadow: 0 0 0 8px #FFF inset;
			}
		}

		&--active
		{
			cursor: default;
		}
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: rgba(#15171b, 0.75);
	overflow: auto;
	z-index: 10;
	backface-visibility: hidden;
	backdrop-filter: blur(10px);

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		min-width: 100%;
		padding: 30px 0;
	}

	&__close
	{
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 30px 30px 0 0;
		background: #FFF;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #BC3232;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1rem;
		}

		&::after
		{
			width: 4px;
			height: 2rem;
			left: 50%;
			top: 50%;
			margin-top: -1rem;
			margin-left: -2px;
		}

		@media (min-width: 768px)
		{
			padding: 55px 55px 0 0;

			&::before
			{
				width: 2.8rem;
				margin-left: -1.4rem;
			}

			&::after
			{
				height: 2.8rem;
				margin-top: -1.4rem;
			}
		}
	}
}
</style>
